@use './../styles/variables' as V;
@import './../styles/base.scss';
@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@900&display=swap');
@import './../styles/colors.scss';
@import './../styles/breakpoints.scss';

.hero-video {
  max-height: 40vh;
  display: flex;
  align-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  z-index: -1;

  @include respond-to('small') {
    max-height: 70vh;
  }

  &:after {
    content:' ';
    position:absolute;
    left:0;
    right:0px;
    height:100px;
    bottom:0;
    background:linear-gradient(
      to bottom,
      rgba(0,0,0,0),
      rgba(0,0,0,1) 100%
    );
    z-index:1;
  }
  &.to-back {
    z-index: 0;
  }
}

.image {
  img {
    max-width: 100%;
    width: 100%;
    height: auto;
    border-radius: 16px;
    overflow: hidden;
  }
  &.full-width {
    width: calc(100% + (V.$padding * 2));
    margin-left: - V.$padding;
    img {
      border-radius: 0px;
    }
  }
}

.avatar {
  margin-right: V.$padding;
  
  img {
    border-radius: 99px;
    width: 80px;
    height: 8s0px;
    overflow: hidden;
  }
}