@import './colors.scss';
@import './breakpoints.scss';
@function tint($color, $percentage) {
  @return mix(white, $color, $percentage)
}
@function shade($color, $percentage) {
  @return mix(black, $color, $percentage)
}

button {
  -webkit-appearance: none;
  -moz-appearance:    none;
  appearance:         none;
  border: none;
  cursor: pointer;
}

.btn {
  padding: 16px 12px;
  width: 100%;
  font-size: 1.1rem;
  margin: 8px 0;
  color: $text-color;
  border-radius: 8px;
  background: $base-color;
  border: 1px solid;
  border-color: $brand-color;
  text-align: center;
  font-weight: bold;
  
  @include respond-to('small') {
    width: auto;
  }
  &.cta {
    background: tint($brand-color, 0%);
    color: $base-color;
    &:hover {
      background: tint($brand-color, 10%);
    }
    &:active {
      background: shade($brand-color, 10%);
    }
  }
}

.btn-group {
  display: flex;
  flex-direction: column;
  gap: 8px;

  :first-child {
    margin-right: 10px;
  }

  @include respond-to('small') {
    flex-direction: row;
  }
  &.horizontal {
    flex-direction: row;
  }
  &.vertical {
    flex-direction: column;
  }
}