@use 'variables';
@import './colors';
@import './breakpoints';

section {
  width: 100%;
}

.group {
  width: 100%; 
  display: flex;
  gap: variables.$padding;
  align-items: center;
  margin-bottom: variables.$padding;
  &.identity {
  }
}

.vertical-align {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  &> * {
    width: 100%;
    margin:0;
    &:first-child {
      margin-bottom: 4px;
    }
  }
}

.two-column {
  display: flex;
  flex-direction: column;
  width: 100%;
  
  @include respond-to('small') {
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    gap: variables.$padding;
      
    .column {
      display: flex;
      flex-direction: column;
      flex-basis: 100%;
      flex: 1;
      &.vertical-center {
        justify-content: center;
      }
    }
  }
}