@use './buttons.scss';
@use './containers.scss';
@use 'variables' as V;
@import './breakpoints.scss';
@import './colors.scss';
@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@400;900&display=swap');


body {
  line-height: 120%;
  font-size: 18px;
  background: $base-color;
  color: $text-color;
  font-family: 'Kanit', sans-serif;
}
h1 {
  font-size: 3.8rem;
  margin-bottom: 1rem;
  line-height: 3.2rem;
  text-transform: uppercase;
  font-weight: bolder;
}
h2 {
  font-size: 2rem;
  font-weight: bolder;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
  line-height: 1.8rem;
}

p {
  margin-bottom: 1rem;
}

hr {
  color: $base-color;
  height: 1px;
  margin: 16px 0;
  background: $base-color;
  border: none;
}

section {
  width: 100%;
  height: auto;
  padding: V.$padding;

  @include respond-to('small') {
    width: auto;
    max-width: 1250px;
    margin: 0 auto;
  
  }

  &.full-width {
    width: 100%;
    max-width: 100%;
    padding: 0;
  }
  &.floating {
    position: absolute;
    height: 100%;
    width: 100%;
  }
  &.negative-top {
    margin-top: -50px;  
    @include respond-to('small') {
      margin-top: - 150px;
    }

  }
}

a {
  text-decoration: none;
  color: $brand-color;
}

.spacer {
  height: 1px;
  &.small {
    height: 10px;
  }
  &.large {
    height: 40px;
  }
}

.construction {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: auto;
}

.padding-text {
  @include respond-to('small') {
    padding:0 30px;
  }
}