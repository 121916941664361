@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@900&display=swap');
@import './../styles/colors.scss';

.menu-open {
  z-index: 2;
  position: fixed;
  top: 20px;
  right: 20px;
}
.menu {
  display: none;
  justify-content: left;
  margin-left: auto;
  text-transform: uppercase;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: $brand-color;
  position: fixed;
  top:0;
  left:0;
  z-index: 1;
  &.show {
    display: flex;
  }

  .menu-item {
    color: $base-color;
    text-decoration: none;
    margin-right: 8px;
    width: 100%;
    font-size: 2.8rem;
    padding: 20px;
    font-weight: 900;
    color: $base-color;
    &:hover {
      background: $base-color;
      color: $brand-color;
    }
    &:active {
      
    }
    &:first-child {
      margin-top: 80px;
    }
  }
}